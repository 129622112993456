import styled from 'styled-components';

import responsive from '../../helpers/responsive';

const Content = styled.div`
  font-size: 18px;
  line-height: 1.4;
  grid-row: 3;
  grid-column: 4 / 14;
  text-align: justify;
  hyphens: auto;

  ${responsive('600px', {
    lineHeight: '1.6',
  })}

  ${responsive('960px', {
    gridColumn: '2 / 16',
  })}
`;

export default Content;
