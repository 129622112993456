import React from 'react';
import { graphql } from 'gatsby';

import createMarkup from '../helpers/createMarkup';
import BaseLayout from '../components/BaseLayout/BaseLayout';
import Content from '../components/Content/Content';

function Terapia(props) {
  const title = props.data.wordpressPage.title;
  const content = props.data.wordpressPage.content;
  const pages = props.data.allWordpressPage.edges;

  return (
    <BaseLayout headerNav={pages} page={title}>
      <Content dangerouslySetInnerHTML={createMarkup(content)} />
    </BaseLayout>
  );
}

export const query = graphql`
  query TerapiaQuery {
    allWordpressPost {
      edges {
        node {
          slug
          content
          title
        }
      }
    }
    wordpressPage(slug: { eq: "psicoterapia" }) {
      id
      excerpt
      title
      content
    }
    allWordpressPage(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          path
          id
        }
      }
    }
  }
`;

export default Terapia;
